define("discourse/plugins/discourse-checklist/discourse/initializers/checklist", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse-common/lib/icon-library", "I18n"], function (_exports, _pluginApi, _ajax, _iconLibrary, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checklistSyntax = checklistSyntax;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function initializePlugin(api) {
    var siteSettings = api.container.lookup("site-settings:main");

    if (siteSettings.checklist_enabled) {
      api.decorateCookedElement(checklistSyntax, {
        id: "checklist"
      });
    }
  }

  function removeReadonlyClass(boxes) {
    boxes.forEach(function (e) {
      return e.classList.remove("readonly");
    });
  }

  function checklistSyntax(elem, postDecorator) {
    if (!postDecorator) {
      return;
    }

    var boxes = _toConsumableArray(elem.getElementsByClassName("chcklst-box"));

    var postWidget = postDecorator.widget;
    var postModel = postDecorator.getModel();

    if (!postModel.can_edit) {
      return;
    }

    boxes.forEach(function (val, idx) {
      val.onclick = function (ev) {
        var box = ev.currentTarget;
        var classList = box.classList;

        if (classList.contains("permanent") || classList.contains("readonly")) {
          return;
        }

        var newValue = classList.contains("checked") ? "[ ]" : "[x]";
        var template = document.createElement("template");
        template.innerHTML = (0, _iconLibrary.iconHTML)("spinner", {
          class: "fa-spin"
        });
        box.insertAdjacentElement("afterend", template.content.firstChild);
        box.classList.add("hidden");
        boxes.forEach(function (e) {
          return e.classList.add("readonly");
        });
        (0, _ajax.ajax)("/posts/".concat(postModel.id), {
          type: "GET",
          cache: false
        }).then(function (result) {
          var blocks = []; // Computing offsets where checkbox are not evaluated (i.e. inside
          // code blocks).

          [// inline code
          /`[^`\n]*\n?[^`\n]*`/gm, // multi-line code
          /^```[^]*?^```/gm, // bbcode
          /\[code\][^]*?\[\/code\]/gm, // italic/bold
          /_(?=\S).*?\S_/gm, // strikethrough
          /~~(?=\S).*?\S~~/gm].forEach(function (regex) {
            var match;

            while ((match = regex.exec(result.raw)) != null) {
              blocks.push([match.index, match.index + match[0].length]);
            }
          });
          [// italic/bold
          /([^\[\n]|^)\*\S.+?\S\*(?=[^\]\n]|$)/gm].forEach(function (regex) {
            var match;

            while ((match = regex.exec(result.raw)) != null) {
              // Simulate lookbehind - skip the first character
              blocks.push([match.index + 1, match.index + match[0].length]);
            }
          }); // make the first run go to index = 0

          var nth = -1;
          var found = false;
          var newRaw = result.raw.replace(/\[(\s|\_|\-|\x|\\?\*)?\]/gi, function (match, ignored, off) {
            if (found) {
              return match;
            }

            nth += blocks.every(function (b) {
              return b[0] >= off + match.length || off > b[1];
            });

            if (nth === idx) {
              found = true; // Do not replace any further matches

              return newValue;
            }

            return match;
          });
          var save = postModel.save({
            raw: newRaw,
            edit_reason: _I18n.default.t("checklist.edit_reason")
          });

          if (save && save.then) {
            save.then(function () {
              postWidget.attrs.isSaving = false;
              postWidget.scheduleRerender();
            }).finally(function () {
              return removeReadonlyClass(boxes);
            });
          } else {
            removeReadonlyClass(boxes);
          }
        }).catch(function () {
          return removeReadonlyClass(boxes);
        });
      };
    });
  }

  var _default = {
    name: "checklist",
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)("0.1", function (api) {
        return initializePlugin(api);
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-checklist/lib/discourse-markdown/checklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  var REGEX = /\[(\s?|x|X)\]/g;

  function getClasses(str) {
    switch (str) {
      case "x":
        return "checked fa fa-check-square-o fa-fw";

      case "X":
        return "checked permanent fa fa-check-square fa-fw";

      default:
        return "fa fa-square-o fa-fw";
    }
  }

  function addCheckbox(result, content, match, state) {
    var classes = getClasses(match[1]);
    var checkOpenToken = new state.Token("check_open", "span", 1);
    checkOpenToken.attrs = [["class", "chcklst-box ".concat(classes)]];
    result.push(checkOpenToken);
    var checkCloseToken = new state.Token("check_close", "span", -1);
    result.push(checkCloseToken);
  }

  function applyCheckboxes(content, state) {
    var match;
    var result = null;
    var pos = 0;

    while (match = REGEX.exec(content)) {
      if (match.index > pos) {
        result = result || [];
        var token = new state.Token("text", "", 0);
        token.content = content.slice(pos, match.index);
        result.push(token);
      }

      pos = match.index + match[0].length;
      result = result || [];
      addCheckbox(result, content, match, state);
    }

    if (result && pos < content.length) {
      var _token = new state.Token("text", "", 0);

      _token.content = content.slice(pos);
      result.push(_token);
    }

    return result;
  }

  function processChecklist(state) {
    var i,
        j,
        l,
        tokens,
        token,
        blockTokens = state.tokens,
        nesting = 0;

    for (j = 0, l = blockTokens.length; j < l; j++) {
      if (blockTokens[j].type !== "inline") {
        continue;
      }

      tokens = blockTokens[j].children; // We scan from the end, to keep position when new tags are added.
      // Use reversed logic in links start/end match

      for (i = tokens.length - 1; i >= 0; i--) {
        token = tokens[i];
        nesting += token.nesting;

        if (token.type === "text" && nesting === 0) {
          var processed = applyCheckboxes(token.content, state);

          if (processed) {
            blockTokens[j].children = tokens = state.md.utils.arrayReplaceAt(tokens, i, processed);
          }
        }
      }
    }
  }

  function setup(helper) {
    helper.registerOptions(function (opts, siteSettings) {
      opts.features["checklist"] = !!siteSettings.checklist_enabled;
    });
    helper.allowList(["span.chcklst-stroked", "span.chcklst-box fa fa-square-o fa-fw", "span.chcklst-box checked fa fa-check-square-o fa-fw", "span.chcklst-box checked permanent fa fa-check-square fa-fw"]);
    helper.registerPlugin(function (md) {
      return md.core.ruler.push("checklist", processChecklist);
    });
  }
});

